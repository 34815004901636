import React, { FunctionComponent } from "react"
import { graphql, Link, PageProps } from "gatsby"
import { MetadataProps, SEOProps } from "../../types/types"
import Layout from "../../layouts/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import EmptyProps from "../../components/empty-props"
import { LinkList } from "../../helpers/style"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import roundedIcon from "../../images/seconds/press-kit/app-icons/seconds-icon-rounded-small.png"
import squareIcon from "../../images/seconds/press-kit/app-icons/seconds-icon-square-small.png"
import roundIcon from "../../images/seconds/press-kit/app-icons/seconds-icon-round-small.png"
import Rule from "../../components/rule"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const PressKitPage: FunctionComponent<PageProps<
  MetadataProps & ScreenshotProps & SEOProps
>> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Press Kit · Seconds Interval Timer"
        description="The Seconds Interval Timer press kit provides screenshots,
          icons, and information about the app which can be used on your own
          website or marketing."
        image={data.seoImage.childImageSharp.sizes.src}
        favIconSizes={[16, 32, 48, 96]}
        favIconTemplate="/images/seconds/favicon-%s.png"
      />
      <Container>
        <Row>
          <Navigation />
          <Col sm={9}>
            <Row>
              <Col>
                <h1>Seconds Interval Timer - Press Kit</h1>
              </Col>
            </Row>
            <ReviewGuide />
            <Facts />
            <Icons />
            <Screenshots
              ipad={data.ipad}
              iphone={data.iphone}
              watch={data.watch}
            />
            <Video />
            <Downloads />
            <Featured />
            <Contact email={data.site.siteMetadata.emails.support} />
          </Col>
        </Row>
      </Container>
      <Rule />
    </Layout>
  )
}

interface ContactProps {
  email: string
}

const Contact: FunctionComponent<ContactProps> = props => {
  return (
    <Row className="my-4">
      <Col>
        <a id="contact"></a>
        <h2>Contact</h2>
        <p>
          For more information please feel free to contact us at{" "}
          <a href={`mailto:${props.email}`}>{props.email}</a>.
        </p>
      </Col>
    </Row>
  )
}

const Featured: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="featured" />
        <h2>Featured</h2>
        <LinkList>
          <li>
            <h5>Feb 2018: Family Circle</h5>
            <p>
              Family Circle recommend Seconds to time their{" "}
              <a href="/assets/seconds-press-kit/featured/201802_family-circle.pdf">
                30 Minutes to Strong Workout
              </a>
              .
            </p>
          </li>
          <li>
            <h5>Oct 2017: Apple Feature</h5>
            <p>
              <a href="/assets/joe-wicks-why-seconds-comes-first-app-store-feature.pdf">
                Apple featured Seconds in the UK App Store
              </a>{" "}
              in their article explaining why{" "}
              <Link to="/seconds-interval-timer/joe-wicks-body-coach">
                Joe Wicks uses Seconds Interval Timer
              </Link>
              .
            </p>
          </li>
          <li>
            <h5>Dec 2016: Strong by Zanna Van Dijk</h5>
            <p>
              <a href="https://twitter.com/zannavandijk">Zanna</a>{" "}
              <a href="/assets/seconds-press-kit/featured/201612_strong_zanna-van-dijk.jpg">
                recommends Seconds
              </a>{" "}
              for timing the workouts in her book{" "}
              <a href="https://www.amazon.co.uk/gp/product/1472242343/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1472242343&linkCode=as2&tag=zanvandij-21">
                Strong
              </a>
              .
            </p>
          </li>
        </LinkList>
      </Col>
    </Row>
  )
}

const Downloads: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="downloads" />
        <h2>Downloads</h2>
        <p>
          All the files from this press kit can be downloaded{" "}
          <a href="/assets/seconds-press-kit.zip">here</a>.
        </p>
      </Col>
    </Row>
  )
}

const Video: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="#videos" />
        <h2>Video</h2>
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe
            src="https://www.youtube.com/embed/xZ5tl3zDcUg"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </ResponsiveEmbed>
      </Col>
    </Row>
  )
}

interface ScreenshotProps {
  ipad: any
  iphone: any
  watch: any
}

const Screenshots: FunctionComponent<ScreenshotProps> = props => {
  const iphone = (props.iphone.nodes as any[]).map(node => {
    return node.childImageSharp
  })
  const ipad = (props.ipad.nodes as any[]).map(node => {
    return node.childImageSharp
  })
  const watch = (props.watch.nodes as any[]).map(node => {
    return node.childImageSharp
  })
  return (
    <Row className="mt-4">
      <Col>
        <a id="screenshots" />
        <h2>Screenshots</h2>
        <h4>iPhone</h4>
        {iphone.map(cis => {
          return (
            <a href={cis.original.src} className="mr-2 mt-2" key={cis.id}>
              <Img fixed={cis.fixed} />
            </a>
          )
        })}

        <h4>iPad</h4>
        {ipad.map(cis => {
          return (
            <a href={cis.original.src} className="mr-2 mt-2" key={cis.id}>
              <Img fixed={cis.fixed} />
            </a>
          )
        })}

        <h4>Apple Watch</h4>
        {watch.map(cis => {
          return (
            <a href={cis.original.src} className="mr-2 mt-2" key={cis.id}>
              <Img fixed={cis.fixed} />
            </a>
          )
        })}
      </Col>
    </Row>
  )
}

const Icons: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="icons" />
        <h2>Icons</h2>
        <Row>
          <Col lg={6} className="mt-4">
            <Row>
              <Col>
                <img
                  src={roundedIcon}
                  alt="Seconds Interval Timer - Rounded Icon"
                  width={150}
                  height={150}
                />
              </Col>
              <Col>
                <h4>Rounded</h4>
                <LinkList>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-rounded.pdf">
                      Vector (PDF)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-rounded.eps">
                      Vector (EPS)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-rounded.png">
                      1024&times;1024 (PNG)
                    </a>
                  </li>
                </LinkList>
              </Col>
            </Row>
          </Col>

          <Col lg={6} className="mt-4">
            <Row>
              <Col>
                <img
                  src={squareIcon}
                  alt="Seconds Interval Timer - Square Icon"
                  width={150}
                  height={150}
                />
              </Col>
              <Col>
                <h4>Square</h4>
                <LinkList>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-square.pdf">
                      Vector (PDF)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-square.eps">
                      Vector (EPS)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-square.png">
                      1024&times;1024 (PNG)
                    </a>
                  </li>
                </LinkList>
              </Col>
            </Row>
          </Col>

          <Col lg={6} className="mt-4">
            <Row>
              <Col>
                <img
                  src={roundIcon}
                  alt="Seconds Interval Timer - Round Icon"
                  width={150}
                  height={150}
                />
              </Col>
              <Col>
                <h4>Round</h4>
                <LinkList>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-round.pdf">
                      Vector (PDF)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-round.eps">
                      Vector (EPS)
                    </a>
                  </li>
                  <li>
                    <a href="/assets/seconds-press-kit/app-icons/seconds-icon-round.png">
                      1024&times;1024 (PNG)
                    </a>
                  </li>
                </LinkList>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const Facts: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="facts" />
        <h2>Facts</h2>

        <table className="table">
          <thead
            className="thead-default"
            style={{ backgroundColor: "#eceeef" }}
          >
            <tr>
              <th scope="col">Downloads</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">2017</th>
              <td align="right">1,417,003</td>
            </tr>
            <tr>
              <th scope="row">Total</th>
              <td align="right">4,809,185</td>
            </tr>
          </tbody>
        </table>

        <table className="table">
          <thead
            className="thead-default"
            style={{ backgroundColor: "#eceeef" }}
          >
            <tr>
              <th scope="col">Weekly Usage</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Users</th>
              <td align="right">137,921</td>
            </tr>
            <tr>
              <th scope="row">Sessions</th>
              <td align="right">296,791</td>
            </tr>
            <tr>
              <th scope="row">Avg. Session Duration</th>
              <td align="right">6 minutes</td>
            </tr>
          </tbody>
        </table>

        <table className="table">
          <thead
            className="thead-default"
            style={{ backgroundColor: "#eceeef" }}
          >
            <tr>
              <th scope="col">Reviews</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">4 or 5 star</th>
              <td align="right">16.2k</td>
            </tr>
            <tr>
              <th scope="row">Total</th>
              <td align="right">17.9k</td>
            </tr>
          </tbody>
        </table>

        <small>Last updated 10 Jan 2018</small>
      </Col>
    </Row>
  )
}

const ReviewGuide: FunctionComponent<EmptyProps> = props => {
  return (
    <Row className="mt-4">
      <Col>
        <a id="review-guide" />
        <h2 className="card-title">Review Guide</h2>
        <h4>What is Seconds?</h4>
        <p>
          Seconds is the app for timing a number of consecutive timed intervals.
          Seconds - a free, Universal app that is also available for Apple Watch
          - was designed for timing High Intensity Interval Training sessions
          but can be used for timing presentations, music practice,
          physiotherapy, broadcasting, special effects, print shops… just about
          anything really.
        </p>
        <p>
          Seconds is developed by Runloop, a husband and wife team based in the
          south of England.
        </p>
        <h4 className="mb-3">Seconds for iOS Features</h4>
        <h5>Create timers with templates</h5>
        <p>
          Quickly create timers in a number of different styles, including HIIT,
          Tabata or Circuit Training with the built in templates. If the
          templates don’t fit your needs custom timers can be created, or you
          can even combine several existing timers into one.
        </p>

        <h5>Match your intensity</h5>
        <p>
          Each interval can be assigned it’s own music allowing you to match the
          intensity of your music to the intensity of your workout.
        </p>

        <h5>Easy on the eyes</h5>
        <p>
          The time display always uses as much space as it can so it is easy to
          see even at a distance. For additional visual cues, intervals can be
          assigned colours.
        </p>

        <h5>Alerts</h5>
        <p>
          Seconds can alert you of interval changes in a number of ways from
          bells to beeps. Perhaps the most popular is for the interval names to
          be spoken. There is even the option to announce the upcoming intervals
          so you have time to prepare.
        </p>

        <h5>Sharing is built right in</h5>
        <p>
          A favourite feature of personal trainers is the ability to send your
          finely crafted timers to other users of Seconds.
        </p>

        <h5>Personal trainer on your wrist</h5>
        <p>
          Seconds for Apple Watch works independently of your iPhone allowing
          you leave your phone at home and receive voice instructions from your
          wrist.
        </p>

        <h5>Pro upgrade</h5>
        <p>
          While you may use all the features of Seconds for free. To use a timer
          more than once or to use imported timers requires a $5 paid upgrade.
        </p>
      </Col>
    </Row>
  )
}

const Navigation: FunctionComponent<EmptyProps> = props => {
  return (
    <Col sm={3}>
      <ul className="nav flex-column">
        <li className="nav-item">
          <a href="#review-guide" className="nav-link">
            Review Guide
          </a>
        </li>
        <li className="nav-item">
          <a href="#facts" className="nav-link">
            Facts
          </a>
        </li>
        <li className="nav-item">
          <a href="#icons" className="nav-link">
            Icons
          </a>
        </li>
        <li className="nav-item">
          <a href="#screenshots" className="nav-link">
            Screenshots
          </a>
        </li>
        <li className="nav-item">
          <a href="#videos" className="nav-link">
            Videos
          </a>
        </li>
        <li className="nav-item">
          <a href="#downloads" className="nav-link">
            Downloads
          </a>
        </li>
        <li className="nav-item">
          <a href="#featured" className="nav-link">
            Featured
          </a>
        </li>
        <li className="nav-item">
          <a href="#contact" className="nav-link">
            Contact
          </a>
        </li>
      </ul>
    </Col>
  )
}

export default PressKitPage

export const query = graphql`
  query PressKitQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    seoImage: file(relativePath: { eq: "images/seconds/promo.jpg" }) {
      childImageSharp {
        sizes {
          src
        }
      }
    }
    ipad: allFile(
      filter: {
        relativePath: { regex: "/seconds/press-kit/screenshots/ipad/gi" }
      }
      sort: { fields: childImageSharp___original___src, order: ASC }
    ) {
      nodes {
        childImageSharp {
          id
          original {
            src
          }
          fixed(height: 150, width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    iphone: allFile(
      filter: {
        relativePath: { regex: "/seconds/press-kit/screenshots/iphone/gi" }
      }
      sort: { fields: childImageSharp___original___src, order: ASC }
    ) {
      nodes {
        childImageSharp {
          id
          original {
            src
          }
          fixed(height: 150, width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    watch: allFile(
      filter: {
        relativePath: { regex: "/seconds/press-kit/screenshots/watch/gi" }
      }
      sort: { fields: childImageSharp___original___src, order: ASC }
    ) {
      nodes {
        childImageSharp {
          id
          original {
            src
          }
          fixed(height: 150, width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
